<template>
  <div class="w-full overflow-x-auto align-middle bg-transparent sm:rounded-lg shadow">
    <div class="sm:rounded-lg overflow-x-auto bg-white border border-hr">
      <table class="w-full divide-y divide-hr">
        <thead class="bg-grey-50">
          <tr>
            <th
              v-for="(heading, headingIndex) in headings"
              :key="'heading' + headingIndex"
              class="px-24 py-12 whitespace-no-wrap text-left text-xs font-medium leading-normal text-grey-400 tracking-wider uppercase"
            >
              <span>{{ heading.label }}</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rowIndex) in items" :key="'row' + rowIndex" class="t-row cursor-pointer even:bg-grey-50">
            <td
              v-for="(heading, colIndex) in headings"
              :key="'col' + colIndex + '/' + rowIndex"
              class="px-24 py-12 whitespace-no-wrap text-left text-sm text-grey-400 tracking-wider leading-normal"
            >
              <slot :name="heading.value" :item="row" :rowIndex="rowIndex" :colIndex="colIndex">
                <span>
                  {{ row[heading.value] || row[heading.value] === 0 ? row[heading.value] : '-' }}
                </span>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ELTable',

  props: {
    items: {
      type: Array,
      default: () => []
    },

    headings: {
      type: Array,
      default: () => []
    }
  }
}
</script>
